import type { PublicRuntimeConfig } from 'nuxt/schema'
import isBot from '~/lib/isBot'
import { injectDatadog } from '~/lib/external-tracking/datadog'
import { injectSegment, injectSegmentPlugin } from '~/lib/external-tracking/segment'
import { injectGtag, injectGtagBase } from '~/lib/external-tracking/googleGtag'
import { injectClarity } from '~/lib/external-tracking/clarity'
import { COOKIES } from '~/constants'

declare global {
  interface Window {
    injected: boolean
  }
}

/**
 * This composable injects scripts into the head of the page.
 */
export default async function useScripts() {
  const runtimeConfig = useRuntimeConfig()
  const { $experiment: experiment } = useNuxtApp()
  const route = useRoute()
  const { user } = useAuthentication()
  const sessionCookie = useCookie(COOKIES.session)

  const { isNotSupported } = await checkBrowserSupport(useRequestHeader('User-Agent'))

  const publicConfig = runtimeConfig.public

  const headers = useRequestHeaders()
  const userAgent = headers['user-agent']

  const { isPotentiallyABot } = useBotScore()

  useHead({
    script: [
      ...injectScripts({
        $config: publicConfig,
        $experiment: experiment,
        $user: user,
        $query: route.query,
        $userAgent: userAgent,
        isPotentiallyABot,
        isNotSupported,
      }),
    ],
  })

  /**
   * Generates the head scripts to inject.
   */
  function injectScripts({ $config, $user, $query, $experiment, $userAgent, isPotentiallyABot, isNotSupported }: { $config: PublicRuntimeConfig, $user: typeof user, $query: typeof route.query, $experiment: typeof experiment, $userAgent: string, isPotentiallyABot: boolean, isNotSupported: boolean }) {
    // Ugly hack in order to prevent the scripts from being injected twice on
    // the client. Perhaps this can removed once scripts are properly handled.
    if (import.meta.client && window?.injected) {
      return []
    }

    // Filter undefined/disabled scripts
    return [
      injectDatadog({
        experiments: $experiment.getAllVariants(),
        isEnabled: $experiment.isEnabled('rvz10584DatadogRumEnabled'),
        isNotSupported,
        sampleRate: Number($experiment.getVariant('rvz11609DatadogRumSampleRate')),
        isBotUserAgent: isBot($userAgent),
        isPotentiallyABot,
        rtSessionId: sessionCookie.value ?? '',
        $config: $config.datadog.common,
        userRole: $user.value?.IsOwner ? 'owner' : 'user',
      }),
      injectSegment({
        isEnabled: $config.segment.enabled,
        rtSessionId: sessionCookie.value ?? '',
        url: $config.segment.url,
        writeKey: $config.segment.writeKey,
      }),
      injectSegmentPlugin({
        isEnabled: $config.segment.enabled,
      }),
      injectGtagBase({
        isEnabled: $config.gtag.enabled,
        id: $config.googleAnalytics.id,
      }),
      injectGtag({
        isEnabled: $config.gtag.enabled,
        analyticsId: $config.googleAnalytics.id,
        adsId: $config.googleAds.id,
        userId: $user.value?.Id.toString(),
        userEmail: $user.value?.Email ?? '',
        query: $query,
      }),
      injectClarity({
        isEnabled: $experiment.isEnabled('rvz20755clarityFeatureFlag') && $config.microsoftClarity.enabled,
        id: $config.microsoftClarity.id,
      }),
      {
        children: `injected = true;`,
      },
    ].flatMap((item) => item || [])
  }
}
