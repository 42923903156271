import type { ExperimentsMapped } from '~/types'

type RUMEvent = {
  error?: {
    message?: string
  }
}

type UserRole = 'owner' | 'user'

/**
 * Injects the Datadog script into the head.
 *
 * __dangerouslyDisableSanitizersByTagID is required to tell Nuxt to not escape
 * characters in this block.
 */
export const injectDatadog = ({
  isEnabled,
  isNotSupported,
  sampleRate,
  isBotUserAgent,
  isPotentiallyABot,
  rtSessionId,
  $config,
  userRole,
  experiments,
}: {
  isEnabled: boolean
  isNotSupported: boolean
  sampleRate: number
  isBotUserAgent: boolean
  isPotentiallyABot: boolean
  rtSessionId: string
  $config: Record<string, unknown>
  userRole: UserRole
  experiments: ExperimentsMapped
}) => {
  if (!isEnabled) {
    return
  }

  return {
    key: 'datadog',
    type: 'text/javascript',
    innerHTML: injectDatadogScript({ isNotSupported, sampleRate, isBotUserAgent, isPotentiallyABot, rtSessionId, $config, userRole, experiments }),
  }
}

/**
 * Generates and returns the actual script content for DataDog to be injected.
 */
const injectDatadogScript = ({
  isNotSupported,
  sampleRate,
  isBotUserAgent,
  isPotentiallyABot,
  rtSessionId,
  $config,
  userRole,
  experiments,
}: {
  isNotSupported: boolean
  sampleRate: number
  isBotUserAgent: boolean
  isPotentiallyABot: boolean
  rtSessionId: string
  $config: Record<string, unknown>
  userRole: UserRole
  experiments: ExperimentsMapped
}) => {
  if (isNotSupported) {
    return ''
  }

  const datadogConfig = {
    ...$config,
    trackUserInteractions: true,
    sessionSampleRate: (isPotentiallyABot || isBotUserAgent) ? 0 : sampleRate,
    beforeSend: (event: RUMEvent) => {
      if (event.error?.message) {
        // filter out error that might be caused by link validation/ pre-fetch rendering from email clicks
        // https://github.com/DataDog/browser-sdk/issues/2715
        const regex = /Object Not Found Matching Id:\\d+,\\sMethodName:\\w+,\\sParamCount:\\d+/
        if (regex.test(event.error.message)) return false
      }
      return true
    },
  }

  return `
  (function(h,o,u,n,d) {
    h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
    d=o.createElement(u);d.async=1;d.src=n
    n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
  })(window,document,'script','https://www.datadoghq-browser-agent.com/us1/v5/datadog-rum-slim.js','DD_RUM');
  const originalWarn = window.console.warn;
  window.console.warn = (...args) => {
    if (args[0] && typeof args[0] === 'string' && args[0].startsWith('[Vue warn]')) {
      args.map((arg) => {
        if (typeof arg === 'string') {
          return arg
        }
        if (typeof arg === 'object') {
          return '<object>'
        }
        return arg
      });
      window?.DD_RUM?.addError(new Error(args.join('')));
    }
    originalWarn(...args);
  };
  window.DD_RUM.onReady(function() {
    var config = ${JSON.stringify($config).replace(/"[*]regex(\/[^/]+?\/[gims]*)"/g, '$1')}
    config.trackUserInteractions = ${datadogConfig.trackUserInteractions};
    config.sessionSampleRate = ${datadogConfig.sessionSampleRate};
    config.beforeSend = ${datadogConfig.beforeSend.toString()};
    window.DD_RUM.setGlobalContextProperty("rtsessionId", "${rtSessionId}");
    window.DD_RUM.setGlobalContextProperty("role", "${userRole}");
    window.DD_RUM.setGlobalContextProperty("experiments", '${JSON.stringify(experiments)}');
    window.DD_RUM.init(config);
  })
  `
}
